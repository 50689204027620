<template>
  <div class="user">
    <header-bar ref="headerBarRef"></header-bar>

    <div class="banner">
      <img src="@/assets/img/base/banner.png" style="width: 100%;" />
    </div>

    <div class="menu">
      <div class="menu-item" @click="tolink('/')">
        <div class="menu-row">
          <img src="@/assets/img/user/menu0.png" class="menu-icon" />
          <div>{{ $t('User.home') }}</div>
        </div>
        <img src="@/assets/img/user/link.png" width="19" />
        <img src="@/assets/img/base/kl.png" class="menu-left" />
        <img src="@/assets/img/base/kr.png" class="menu-right" />
      </div>
      <div class="menu-item" @click="tolink('Chongzhi')">
        <div class="menu-row">
          <img src="@/assets/img/user/menu1.png" class="menu-icon" />
          <div>{{ $t('User.chongzhi') }}</div>
        </div>
        <img src="@/assets/img/user/link.png" width="19" />
        <img src="@/assets/img/base/kl.png" class="menu-left" />
        <img src="@/assets/img/base/kr.png" class="menu-right" />
      </div>
      <div class="menu-item" @click="tolink('Bonus')">
        <div class="menu-row">
          <img src="@/assets/img/user/bonus.svg" class="menu-icon" />
          <div>{{ $t('User.bonus') }}</div>
        </div>
        <img src="@/assets/img/user/link.png" width="19" />
        <img src="@/assets/img/base/kl.png" class="menu-left" />
        <img src="@/assets/img/base/kr.png" class="menu-right" />
      </div>
      <div class="menu-item" @click="tolink('AiData')">
        <div class="menu-row">
          <img src="@/assets/img/user/menu2.png" class="menu-icon" />
          <div>{{ $t('User.ai') }}</div>
        </div>
        <img src="@/assets/img/user/link.png" width="19" />
        <img src="@/assets/img/base/kl.png" class="menu-left" />
        <img src="@/assets/img/base/kr.png" class="menu-right" />
      </div>
      <div class="menu-item" @click="tolink('Mining')">
        <div class="menu-row">
          <img src="@/assets/img/user/menu3.png" class="menu-icon" />
          <div>{{ $t('User.mining') }}</div>
        </div>
        <img src="@/assets/img/user/link.png" width="19" />
        <img src="@/assets/img/base/kl.png" class="menu-left" />
        <img src="@/assets/img/base/kr.png" class="menu-right" />
      </div>
      <div class="menu-item" @click="tolink('Notice')">
        <div class="menu-row">
          <img src="@/assets/img/user/menu4.png" class="menu-icon" />
          <div>{{ $t('User.notice') }}</div>
        </div>
        <img src="@/assets/img/user/link.png" width="19" />
        <img src="@/assets/img/base/kl.png" class="menu-left" />
        <img src="@/assets/img/base/kr.png" class="menu-right" />
      </div>
      <!-- <div class="menu-item">
        <div class="menu-row">
          <img src="@/assets/img/user/menu5.png" class="menu-icon" />
          <div>资料下载</div>
        </div>
        <img src="@/assets/img/user/link.png" width="19" />
        <img src="@/assets/img/base/kl.png" class="menu-left" />
        <img src="@/assets/img/base/kr.png" class="menu-right" />
      </div> -->

      <!-- <div class="btn" @click="logout">退出登录</div> -->
    </div>

    <tab-bar active="3"></tab-bar>
  </div>
</template>

<script>
import HeaderBar from '@/components/HeaderBar'
import TabBar from '@/components/TabBar'
import { ref } from 'vue'

export default {
  name: 'User',
  components: {
    HeaderBar,
    TabBar
  },
  setup() {
    return {
      us: ref({})
    }
  },
  created() {
  },
  methods: {
    tolink(url) {
      this.$router.push(url)
    },
    toOfficial() {
      window.open(this.$config.send_url + "#/Official")
    },
    onRefresh() {
      this.$refs.headerBarRef.getShowAddress()
    },
    logout: function () {
      this.$utils.setloc("token", "");
      this.$store.commit("setLoginState", false);
      this.$router.push("Login")
      localStorage.clear()
    }
  }
}
</script>

<style scoped>
.user {
  overflow-x: hidden;
  background-color: #f3f3f3;
}

.banner {
  margin-top: -75px;
  padding: 0 10px;
}

.menu {
  padding: 20px 10px;
}

.menu-item {
  position: relative;
  margin-bottom: 20px;
  padding: 0 20px;
  height: 57px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 16px;
  border: 1px solid #000;
  ;
  background-color: #fff;
}

.menu-item:last-child {
  margin-bottom: 0;
}

.menu-row {
  display: flex;
  align-items: center;
}

.menu-icon {
  margin-right: 10px;
  width: 24px;
  height: 24px;
}

.menu-left {
  position: absolute;
  height: 85px;
  width: 60px;
  top: -13px;
  left: -12px;
}

.menu-right {
  position: absolute;
  height: 85px;
  width: 60px;
  top: -13px;
  right: -12px;
}

.btn {
  margin: 10px 0 0;
  height: 60px;
  line-height: 60px;
  text-align: center;
  font-size: 16px;
  font-weight: bold;
  border-radius: 14px;
  background-color: #91eb0d;
}
</style>